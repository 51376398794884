import React from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import avatarConfident from '../../../../../../../assets/avatar_excited.svg';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { parseAmountWithCurrency } from '../../../../../../../utils/helpers';

type LoanProposalProps={
    loadingSimulation:boolean,
    formSchema: FormSchemaV3,
    recommendedAmount:{amount: number,
         periods: number,
          installment: number,
          interestRate: number
        },
    isSubmitting: boolean,
    handleShowSimulator: ()=>void,
}

export default function LoanProposal(props:LoanProposalProps) {
  const {
    loadingSimulation,
    formSchema,
    recommendedAmount,
    isSubmitting,
    handleShowSimulator,
  } = props;
  const theme = useTheme();
  return (
    <>
      <div>
        <img
          src={avatarConfident}
          alt="Avatar"
          height="60px"
          aria-hidden
        />
      </div>

      <Typography
        data-pw="preloan-loan-proposal-title"
        variant="h5"
        gutterBottom
        fontWeight="bold"
      >
        {!loadingSimulation ? `¡Felicidades, ${formSchema.firstName}!` : 'Un momento...'}
      </Typography>
      <Divider style={{ marginBottom: '3%' }} />
      {!loadingSimulation
        ? (
          <>
            <Typography
              fontWeight="bold"
              variant="h6"
              gutterBottom
              style={{ lineHeight: '27px' }}
            >
              Tienes pre-aprobados
              {' '}
              <span style={{ color: theme.customPalette.tertiary.main }}>
                {parseAmountWithCurrency(
                  formSchema.loanParams.currency,
                  recommendedAmount.amount,
                  false,
                  true,
                )}
                {' '}
                a
                {' '}
                {recommendedAmount.periods}

                {' '}
                {`mes${recommendedAmount.periods > 1 ? 'es' : ''}`}
              </span>
              {' '}
              con una tasa de
              {' '}
              <span style={{ color: theme.palette.secondary.main }}>

                {recommendedAmount.interestRate.toLocaleString('es-CL', { style: 'percent', maximumFractionDigits: 2 })}
              </span>
              {' '}
              y una
              cuota
              {' '}
              <span style={{ textDecoration: 'underline' }}>aproximada</span>
              {' '}
              de
              {' '}
              <span style={{ color: theme.palette.secondary.main }}>
                {parseAmountWithCurrency(
                  formSchema.loanParams.currency,
                  recommendedAmount.installment,
                  false,
                  true,
                )}
                {' '}
                {recommendedAmount.periods > 1 && <span>mensuales</span>}
              </span>
            </Typography>
            <Divider />
            <Typography
              fontStyle="italic"
              variant="body2"
              style={{
                backgroundColor: 'whitesmoke', padding: 10, borderRadius: 12, marginBlock: 15,
              }}
            >
              ¡A continuación podrás modificar tus condiciones si lo estimas conveniente!
            </Typography>
            <Typography fontWeight="bold" align="center" variant="body2" component="div" color="secondary">
              {formSchema.loanParams.basePaymentMethod === 'pac' ? 'Forma de cobro: Pago automático con cuenta bancaria' : 'Forma de cobro: Descuento por planilla'}
            </Typography>
            <div>
              <Button
                variant="outlined"
                style={{ marginTop: 10 }}
                onClick={() => {
                  handleShowSimulator();
                }}
                disabled={isSubmitting}
              >
                Modificar
              </Button>
            </div>
            {formSchema.origin === 'brincus'
            && (
            <div style={{ fontSize: 12, marginTop: 10 }}>
              Recuerda que este monto equivale a
              {' '}
              <b>
                {recommendedAmount.amount / formSchema.loanParams.amountStepSize}
                {' '}
                plan
                {' '}
                {(recommendedAmount.amount / formSchema.loanParams.amountStepSize) > 1
                  ? <span>es</span> : <span />}
                {' '}
                <span>
                  de colegio online
                </span>
              </b>
              {' '}
              de Brincus
            </div>
            )}
          </>
        )
        : (
          <div>
            <CircularProgress size={30} color="inherit" />
            <Typography> Estamos analizando tus datos...</Typography>
            <Typography color="text.secondary">El analisis de tus datos podría tardar hasta un minuto. ¡Tendrás tu respuesta lo antes posible!</Typography>
          </div>
        )}
    </>
  );
}
