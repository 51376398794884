import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import avatarExcited from '../../assets/avatar_excited.svg';
import avatarStressed from '../../assets/avatar_stressed.svg';

type DataLoadingProps = {
    avatar?: string,
    firstText?: string|null,
    secondText?: string|null,
    error?: boolean|undefined
}

export default function DataLoading(props:DataLoadingProps) {
  const {
    avatar, firstText, secondText, error,
  } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '85%', margin: 'auto' }}
    >
      <Grid item justifyContent="center" alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <object data={!error ? avatar : avatarStressed} width="120px" style={{ marginInline: 'auto', marginBottom: '50px' }} aria-labelledby="Bancamin" />
          {!error && <CircularProgress size={50} />}
        </div>
        <Typography
          variant="h4"
          style={{ textAlign: 'center' }}
          fontWeight="bold"
        >
          <br />
          {!error ? firstText : <span>¡Ha ocurrido un error!</span>}
        </Typography>
        <br />
        <Typography
          variant="h5"
          style={{ textAlign: 'center' }}
        >
          {!error ? secondText : '¡Por favor contáctanos si el problema persiste!'}
        </Typography>
      </Grid>
    </Grid>
  );
}

DataLoading.defaultProps = {
  avatar: avatarExcited,
  firstText: null,
  secondText: null,
  error: false,
};
