import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Divider, Fade, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import loanDetailStyles from './styles';
import type { LoanDetailProps } from '../../../types/account';
import { parseAmountWithCurrency } from '../../../utils/helpers';
import analytics from '../../../utils/analytics';

export default function LoanDetails(props: LoanDetailProps) {
  const {
    loanNumber,
    installmentAmount,
    lastInstallmentAmount,
    periods,
    paidQuotes,
    unpaidAmount,
    cae,
    interestRate,
    toPayQuotes,
    currency,
  } = props;
  const classes = loanDetailStyles();

  React.useEffect(() => {
    analytics.page('PU - TU CREDITO', 'DETALLE PRODUCTO');
  }, []);

  return (
    <Fade in {... ({ timeout: 1500 })}>
      <div style={{ margin: '1%' }}>
        <Typography gutterBottom align="left" style={{ borderRadius: '15px' }}>
          Detalle del crédito
        </Typography>
        <Accordion defaultExpanded square style={{ marginBottom: 10, borderRadius: '15px 15px 0px 0px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: 'whitesmoke', borderRadius: '15px 15px 0px 0px' }}
          >
            <Typography>I. Producto comercial</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Código del crédito
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {loanNumber}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Plazo del crédito pendiente
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {toPayQuotes}
                {' '}
                meses
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Número de cuota
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {paidQuotes + 1}
                /
                {periods}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Saldo del crédito
                {' '}
                {(currency.abbreviation === 'CLF' && <span>(UF)</span>)}
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                { parseAmountWithCurrency(currency, unpaidAmount)}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Monto cuota
                {' '}
                {(currency.abbreviation === 'CLF' && <span>(UF)</span>)}
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {parseAmountWithCurrency(currency, installmentAmount)}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Monto última cuota
                {' '}
                {(currency.abbreviation === 'CLF' && <span>(UF)</span>)}
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {parseAmountWithCurrency(currency, lastInstallmentAmount)}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Tasa de interés
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {(interestRate / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Carga anual equivalente
              </Typography>
              <Typography align="left" className={classes.AccordionItemRightContent}>
                {(cae / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}
              </Typography>
            </div>
            <Divider />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded square style={{ marginBottom: '10px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <Typography>II. Condiciones de prepago</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Disponibilidad
              </Typography>
              <Typography variant="body2" align="left" className={classes.AccordionItemRightContent} style={{ width: '50%' }}>
                Para prepagar tu crédito con Banca.Me debes
                prepagar al menos un 25% de la deuda que tienes actuamente con nosotros.
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Cargo prepago
              </Typography>
              <Typography variant="body2" align="left" className={classes.AccordionItemRightContent} style={{ width: '50%' }}>
                Para créditos no reajustables: 30 días de interés sobre el capital prepagado
              </Typography>
            </div>
            <div className={classes.AccordionItem}>
              <Typography align="left" style={{ width: '50%' }}>
                Plazo de aviso para prepago
              </Typography>
              <Typography variant="body2" align="left" className={classes.AccordionItemRightContent} style={{ width: '50%' }}>
                Sin plazo de aviso de prepago
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded square style={{ borderRadius: '0px 0px 15px 15px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <Typography>III. Costos por atraso</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.AccordionDetails}>
            <div className={classes.AccordionItem}>
              <Typography align="left">
                Moratorio
              </Typography>
              <Typography variant="body2" align="left" className={classes.AccordionItemRightContent} style={{ width: '50%' }}>
                El mayor valor entre la tasa de interés máxima convencional
                vigente al momento del otorgamiento del crédito o a la época de la mora o retardo
              </Typography>
            </div>
            <Divider />
            <div className={classes.AccordionItem} style={{ display: 'block', textAlign: 'justify' }}>
              <Typography gutterBottom align="left">
                Cobranza
              </Typography>
              <br />
              <div>
                En conformidad a las instrucciones emitidas en la Circular Nº 2.815 de la
                Superintendencia de
                Bancos e Instituciones Financieras, y a la ley Nº 19.496, informamos a
                Usted los honorarios de
                la empresa desea de cobranza externa, Bancame Spa, o
                quien ésta designe, cuyas tarifas son
                aplicadas en caso de mora o retraso en el pago de crédito.

              </div>
              <table style={{ border: '1px solid black', marginTop: 10 }}>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ borderBottom: '1px solid black' }}>
                      <span style={{ fontWeight: 'bold' }}>CRÉDITOS EN CUOTAS Y LINEAS DE CRÉDITO.</span>
                      {' '}
                      El honorario se aplicará transcurrido los
                      primeros 20 días de atraso, como porcentaje
                      sobre la cuota o saldo impago, según la
                      siguiente escala progresiva:

                    </td>
                  </tr>
                  <tr>
                    <th>Monto de cuota o saldo</th>
                    <th>
                      Honorarios
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: '#dddddd' }}>
                    <td>Por obligación hasta 10 UF</td>
                    <td style={{ fontWeight: 'bold' }}>8%</td>
                  </tr>
                  <tr>
                    <td>Por la parte que excede de 10 y hasta 50 UF</td>
                    <td style={{ fontWeight: 'bold' }}>5%</td>
                  </tr>
                  <tr style={{ backgroundColor: '#dddddd' }}>
                    <td>Por la parte que exceda de 50 UF</td>
                    <td style={{ fontWeight: 'bold' }}>2,5%</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ borderTop: '1px solid black' }}>
                      En caso de aceleración del saldo de deuda, los porcentajes
                      de honorarios a cobrar son los
                      mismos anteriores,
                      pero se calculan sobre el total del capital adeudado.
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ fontWeight: 'bold', marginTop: 10 }}>RECUERDE QUE:</div>
              <ul>
                <li>Pagando oportunamente evitará gastos de cobranza</li>
                <li>
                  Si no recibe su estado de cuenta o extravió su cuponera de pago,
                  debe contactarse con
                  el servicio al cliente antes de la fecha de pago y cancelar su cuenta

                </li>
                <li>
                  Si el crédito es enviado a cobranza judicial, las costas judiciales
                  serán de cargo del
                  deudor.
                </li>
              </ul>
              <div style={{ fontWeight: 'bold', marginTop: 10 }}>OBSERVACIONES:</div>
              <div>
                En caso de mora o retardo en el pago de sus obligaciones y con el objeto de
                que Usted tome el
                debido y oportuno conocimiento, Bancame le comunicará la situación
                de encontrarse en mora,
                mediante alguno de los siguientes medios, dentro de los primeros
                20 días de cada vencimiento
                impago

              </div>
              <ul>
                <li>Llamada telefónica (al cliente titular de la deuda y/o al(os) aval(es))</li>
                <li>
                  SMS, Whatsapp o cualquier otra aplicación similar a los celulares que Bancame
                  tenga
                  registrado asociado al cliente

                </li>
                <li>Mensaje con voz grabada al celular</li>
                <li>Correo electrónico</li>
                <li>Mensaje en sitio privado del portal Bancame (www.banca.me)</li>
                <li>Carta dirigida al(os) domicilio(s) registrado(s) por el cliente en Bancame</li>
              </ul>
              <div>
                Esta gestión de aviso es realizada por Bancame sin cargo para el deudor.
                Valores vigentes para crédito otorgados a partir del 15 de Octubre de 2020.

              </div>
              <div>
                Esta gestión de aviso es realizada por Bancame sin cargo para el deudor.
                Valores vigentes para crédito otorgados a partir del 15 de Octubre de 2020.

              </div>
              <div>
                Las modalidades y procedimientos de cobranza extrajudicial pueden ser cambiados
                anualmente en el caso de operaciones a más de un año. Las futuras
                modificaciones de estos
                honorarios serán comunicados mediante volante o
                aviso informativo, que estarán disponibles
                en la página web de Bancame.
              </div>
              <div>
                La cobranza se realizará de lunes a sábado, excepto
                festivos, de 08:00 a 20:00 horas.

              </div>
              <div>
                El procedimiento de cobranza extrajudicial contempla la
                eventual información de la situación
                de morosidad a terceros en conformidad a la Ley Nº 19.628
                especialmente a Bases de Datos,
                Sinacofi, Comisión para el Mercado Financiero.

              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Fade>
  );
}
