import React from 'react';
import {
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import avatarExcited from '../../../../../../../assets/avatar_excited.svg';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';
import { parseAmountWithCurrency } from '../../../../../../../utils/helpers';
import { Currency } from '../../../../../../../types/account';

type AcceptedLoanDetailsProps ={
    requestLoanData:FormSchemaV3['requestLoanData'],
    currency: Currency
}

export default function AcceptedLoanDetails(props:AcceptedLoanDetailsProps) {
  const { requestLoanData, currency } = props;
  const theme = useTheme();

  const productName = () => {
    if (requestLoanData.periods < 2) {
      return 'adelanto';
    }
    return 'crédito';
  };

  return (
    <>
      <div>
        <object
          data={avatarExcited}
          aria-labelledby="Avatar"
          height="60px"
        />
      </div>
      <Typography
        data-pw="preloan-accepted-loan-title"
        variant="h5"
        gutterBottom
        fontWeight="bold"
      >
        ¡Estamos!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Este es el detalle del
        {' '}
        {productName()}
        {' '}
        que solicitarás

      </Typography>
      <Divider />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        margin: '5px auto',
        padding: 7,
        backgroundColor: 'whitesmoke',
        borderRadius: '10px',
      }}
      >
        <div>
          <Typography color="text.secondary">Monto total</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.amount,
              false,
              true,
            )}
          </Typography>
        </div>

        <div>
          <Typography color="text.secondary">Plazo</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.periods}
            {' '}
            meses
          </Typography>
        </div>
        <div>
          <Typography color="text.secondary">Costo de originación</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.originCost,
              false,
              true,
            )}
          </Typography>
        </div>
        <div>
          <Typography color="text.secondary">
            Impuestos
            {' '}
            {+requestLoanData.insuranceCost > 0 ? <span>y seguros</span> : <span />}
          </Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.taxes + requestLoanData.insuranceCost,
            )}
          </Typography>
        </div>

        <div>
          <Typography color="text.secondary">Tasa de interés</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.interestRate.toLocaleString(
              'es-CL',
              { style: 'percent', maximumFractionDigits: 2 },
            )}
          </Typography>
        </div>
        <div>
          <Typography color="text.secondary">CAE aproximado</Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h5"
            gutterBottom
          >
            {requestLoanData.CAE.toLocaleString(
              'es-CL',
              { style: 'percent', maximumFractionDigits: 2 },
            )}
          </Typography>
        </div>
      </div>
      <Divider />
      <div style={{ marginTop: '4.5%' }}>
        <div>
          <Typography
            fontWeight="bold"
            color="text.secondary"
          >
            Monto cuota aproximada

          </Typography>
          <Typography
            color="secondary"
            fontWeight="bold"
            variant="h4"
            gutterBottom
          >
            {parseAmountWithCurrency(
              currency,
              requestLoanData.installment,
            )}
          </Typography>
        </div>
        <Typography
          color="text.secondary"
          fontWeight="bold"
        >
          Costo total aproximado del
          {' '}
          {productName()}
        </Typography>
        <Typography
          fontWeight="bold"
          variant="h4"
          gutterBottom
          style={{ color: theme.customPalette.tertiary.main }}
        >
          {parseAmountWithCurrency(
            currency,
            requestLoanData.totalCost,
          )}
        </Typography>
      </div>
    </>
  );
}
